const l = {
  "base.and": "og",
  "base.toc": "Vilkår & Betingelser",
  "base.privacyPolicy": "Fortrolighedspolitik",
  "base.createAccount": "Opret konto",
  "base.signin": "Log ind",
  "base.signup": "Tilmeld",
  "base.logOut": "Log ud",
  "authentication.description": "Log ind eller opret en konto",
  "invitation.wrongEmailTitle": "Allerede logget ind",
  "invitation.wrongEmailText":
    "Det ser ud til, at du allerede er logget ind på en anden konto. Log venligst ud og prøv igen.",
  "invitation.invalidLinkTitle": "Ugyldig invitation",
  "invitation.invalidLinkText": "Invitationslinket er ugyldigt",
  "invitation.completeSignup": "Venligst afslut tilmeldingen til ",
  "invitation.completeSignupDescription":
    "En verifikationsmail er blevet sendt til din adresse. Hvis du ikke ser den i din indbakke, bedes du tjekke din spammappe.",
  "invitation.completeSignupButton": "Afslut Tilmelding",
  "invitation.mustSignupTitle": "Du er blevet inviteret til ",
  "invitation.signInToAccept":
    "Log venligst ind for at acceptere invitationen. I den invitationsmail, du modtog, vil du finde en midlertidig adgangskode.",
  "invitation.acceptTermsOnSignup": "Ved at logge ind accepterer du automatisk vores",
  "invitation.accept": "Log ind for at acceptere invitationen",
  "invitation.failed":
    "Accept af invitation mislykkedes. Prøv venligst at logge ud og logge ind - eller bed administratoren om at invitere dig igen.",
  "invitation.accepted": "Invitation accepteret",
  "invitation.emailNotVerified": "Email ikke verificeret. Bekræft venligst din email og prøv igen.",
  "country.venezuela": "Venezuela",
  "country.japan": "Japan",
  "country.algeria": "Algeriet",
  "country.france": "Frankrig",
  "country.spain": "Spanien",
  "country.china": "Kina",
  "country.usa": "USA",
  "country.england": "England",
  "country.brazil": "Brasilien",
  "country.italy": "Italien",
  "country.denmark": "Danmark",
  "head.title": "Talkio AI | Øv Sprog med AI",
  "landing.title1": "Øv ",
  "landing.title2": "Sprog ",
  "landing.title3": "med AI ",
  "landing.subtitle1":
    "Den ultimative sprogtræningsapp, der bruger AI-teknologi til at hjælpe dig med at forbedre dine mundtlige færdigheder.",
  "landing.button.freeTrial": "7 dage gratis",
  "landing.creditCardNotice": "Kreditkort kræves - opsig når som helst",
  "landing.text.voiceControlTeam": "Fra teamet bag Voice Control for ChatGPT",
  "landing.text.trustedBy": "Betroet af mere end 500.000 brugere",
  "landing.card.title.advancedAI": "Bygget med Avanceret AI Teknologi",
  "landing.card.text.advancedAI":
    "Talkio AI er bygget med avanceret AI teknologi og lader dig interagere med AI'en gennem stemme for at træne dine mundtlige sprogfærdigheder.",
  "landing.card.title.multipleLanguages": "Understøtter Flere Sprog",
  "landing.card.text.multipleLanguages":
    "Vi tilbyder et bredt udvalg af sprogindstillinger, herunder engelsk, kinesisk, fransk og flere andre sprog.",
  "landing.card.title.premiumVoice": "Premium Stemmeteknologi",
  "landing.card.text.premiumVoice":
    "Talkio AI tilbyder premium stemmer og understøtter flere dialekter for de mest populære sprog. Med vores avancerede sprog teknologi kan du fordybe dig i autentiske samtaler og opnå færdigheder i de dialekter, der betyder mest for dig.",
  "landing.sectionTitle.meetTutors": "Mød AI Lærerne",
  "landing.sectionText.moreThanTutors":
    "Talkio AI har mere end 400 lærere med unikke kunstige personligheder klar til at hjælpe dig på din sprogrejse.",
  "landing.text.andManyMore": "Og mange flere",
  "landing.text.learnWithAITutors": "Lær med AI Lærere",
  "landing.text.wonderedAboutTutor":
    "Har du nogensinde undret dig over, hvordan det ville være at have en personlig sprogunderviser tilgængelig når som helst, hvor som helst? Hos Talkio AI gør vi denne drøm til virkelighed. Vores AI-lærere er de perfekte følgeledsagere til at forbedre dine mundtlige færdigheder. Drevet af avanceret AI-teknologi efterligner de menneskelig interaktion og samtale og tilbyder en engagerende og effektiv sprogindlæringsoplevelse.",
  "landing.text.rememberLanguageLearning":
    "Husk, sprogindlæring er mere end blot grammatik og ordforråd; det handler om at udtrykke dig selv med selvtillid. Med Talkio AIs intelligente lærere kan du tale, øve og overvinde dine sprog mål!",
  "landing.sectionTitle.featureSpotlight": "Funktion Spotlight",
  "landing.sectionText.featureSpotlight":
    "Naviger gennem vores suite af funktioner, designet til at gøre din sprogindlæring til en fordybende og engagerende rejse.",
  "landing.text.safeEnvironment": "Sikker og Støttende Miljø",
  "landing.text.supportiveEnvironment":
    "Vores app giver et sikkert og støttende miljø for dig til at øve dig i at tale med selvtillid. Med Talkio AI kan du have meningsfulde samtaler med hjælp fra AI-teknologi, der giver dig mulighed for at opnå flydende færdigheder og udtrykke dig selv på det sprog, du lærer.",
  "landing.sectionTitle.insights": "Indsigter",
  "landing.sectionText.realStories": "Virkelige historier fra virkelige brugere af Talkio AI",
  "landing.sectionTitle.faq": "Ofte Stillede Spørgsmål",
  "landing.sectionText.faq": "Svar på hyppige spørgsmål om Talkio AI",
  "landing.sectionTitle.pricing": "Priser",
  "landing.sectionText.unlockPotential":
    "Lås op for dit sprogpotentiale med Talkio. Din rejse mod mestring starter nu.",
  "landing.sectionText.offersForPartners": "Vi tilbyder også planer for skoler og teams",
  "landing.imageAlt.landing": "Landing",
  "landing.imageAlt.laurelLeft": "Laurbær venstre",
  "landing.imageAlt.laurelRight": "Laurbær højre",
  "landing.imageAlt.speakingWithAI": "Kvinde der taler med AI",
  "testimonial.text1":
    "Jeg har personligt brugt Talkio AI til at forbedre mit spansk, og lad mig sige dig, det er en utrolig mulighed.",
  "testimonial.text2":
    "Talkio AI er et meget imponerende værktøj og bør inkluderes i enhver sprogundervisers værktøjer.",
  "testimonial.text3": "Dette værktøj Talkio AI overgår mine forventninger for sprogindlæring.",
  "testimonial.text4": "Talkio AI - det er et fantastisk produkt.",
  "feature.lifeLikeConversations": "Livagtige samtaler med AI-lærere",
  "feature.pronunciationTool": "Udtalelsesprøvningsværktøj",
  "feature.detailedFeedback": "Detaljeret feedback på dine tale færdigheder",
  "feature.interactiveWordbook": "Interaktiv ordbog",
  "feature.supportsLanguages": "Understøtter 40+ sprog",
  "landing.text.voiceConversations": "Stemmesamtaler",
  "landing.text.voiceConversationsDescription":
    "Ha' engagerende stemmesamtaler med vores AI-lærere",
  "landing.text.pronunciation": "Udtale",
  "landing.text.pronunciationDescription": "Vurder og øv din udtale med ord-for-ord feedback",
  "landing.text.feedback": "Feedback",
  "landing.text.feedbackDescription":
    "Få øjeblikkelig feedback på dine sprogkundskaber, og tips til at forbedre",
  "landing.text.multipleLanguages": "Flere Sprog og Dialekter",
  "landing.text.multipleLanguagesDescription":
    "Vælg mellem 134 sprog og dialekter for at øve dine tale færdigheder",
  "landing.text.progress": "Fremskridt",
  "landing.text.progressDescription":
    "Hold styr på dine fremskridt og få ugentlige rapporter om din sprogindlæringsrejse",
  "landing.text.wordbook": "Ordbog",
  "landing.text.wordbookDescription": "Gem og genbesøg ord lært under træning",
  "landing.text.streaks": "Serier",
  "landing.text.streaksDescription":
    "Hold styr på dine fremskridt med serier for at opbygge en vane med indlæring",
  "landing.text.crosstalk": "Samtale",
  "landing.text.crosstalkDescription":
    "Kommuniker med læreren på både dit modersmål og det sprog, du lærer",
  "landing.text.translations": "Oversættelser",
  "landing.text.translationsDescription":
    "Øjeblikkelige oversættelser for at støtte dig i dine samtaler",
  "landing.text.featureSpotlight": "Funktion Spotlight",
  "landing.text.featureSpotlightDescription":
    "Naviger gennem vores suite af funktioner, designet til at gøre din sprogindlæring til en fordybende og engagerende rejse",
  "feature.practiceSpeaking.title": "Øv at tale",
  "feature.practiceSpeaking.bullet1": "Livagtige samtaler med en AI-lærer",
  "feature.practiceSpeaking.bullet2": "Stemmes første interaktion",
  "feature.practiceSpeaking.bullet3": "Samtalehjælp",
  "feature.practiceSpeaking.bullet4": "Gentag & Prøv igen",
  "feature.worldOfLanguages.title": "En Verden af Sprog",
  "feature.worldOfLanguages.bullet1": "Understøtter 40+ Sprog",
  "feature.worldOfLanguages.bullet2": "Øjeblikkelige oversættelser",
  "feature.worldOfLanguages.bullet3": "Multisproget Talegenkendelse",
  "feature.worldOfLanguages.bullet4": "Romanisering af Skriftsprog",
  "feature.actionableFeedback.title": "Handlingbar Feedback",
  "feature.actionableFeedback.bullet1": "Sætning Scoring",
  "feature.actionableFeedback.bullet2": "Forbedringsstrategier",
  "feature.actionableFeedback.bullet3": "AI Forslag",
  "feature.actionableFeedback.bullet4": "Talepræcisionsscore",
  "feature.pronunciationPractice.title": "Udtale Øvelse",
  "feature.pronunciationPractice.bullet1": "Ord-for-ord Score",
  "feature.pronunciationPractice.bullet2": "Udtale Vurdering",
  "feature.pronunciationPractice.bullet3": "Nøjagtighed, Fuldførelsesgrad & Flydende",
  "feature.pronunciationPractice.bullet4": "Gentag & Prøv igen",
  "feature.interactiveWordbook.title": "Interaktiv Ordbog",
  "feature.interactiveWordbook.bullet1": "Hold Styr på de Ord, Du Lærer",
  "feature.interactiveWordbook.bullet2": "Forstærket Memorering via Lærerens Gentagelse",
  "feature.interactiveWordbook.bullet3": "Oversættelse & Læs højt",
  "feature.interactiveWordbook.bullet4": "Sætningseksempler",
  "feature.button.startTrial": "Start Din Gratis Prøve",
  "faq.question1": "Er der en gratis plan?",
  "faq.answer1":
    "Nej, alle planer er betalte. Der er dog en 7-dages gratis prøve, der kan annulleres når som helst. Ingen forpligtelser.",
  "faq.question2": "Hvem står bag Talkio AI?",
  "faq.answer2.part1": "Talkio AI er et produkt af",
  "faq.answer2.part2":
    "opført i Den Europæiske Union. Aidia ApS overholder den Generelle Databeskyttelsesforordning (GDPR) for at levere en sikker og tryg oplevelse for alle brugere.",
  "faq.question3": "Er Talkio AI velegnet til begyndere?",
  "faq.answer3":
    "Talkio AI er bedst egnet til dem med en grundlæggende viden om et sprog, der sigter efter at forbedre deres mundtlige færdigheder. For begyndere har vi udviklet en introduktionsguide, der hjælper dig med at komme i gang. Denne guide dækker grundlæggende aspekter af det valgte sprog, så du kan få fuld gavn af Talkio AI. Det er gratis og et fantastisk værktøj til lærende, der starter deres rejse. Tjek guiden ud og begynd at forbedre dine sprogfærdigheder på en effektiv måde.",
  "faq.button.gettingStartedGuide": "Prøv den GRATIS introduktionsguide",
  "faq.question4": "Er betalingen sikker?",
  "faq.answer4":
    "Ja, vi bruger Stripe til økonomisk behandling. Vi opbevarer ikke nogen af dine kreditkortoplysninger.",
  "faq.question5": "Hvor er mine data gemt?",
  "faq.answer5":
    "Dine data er gemt på servere i Den Europæiske Union: Microsoft (stemme og data), Auth0 (autentificering), Stripe (betalinger). Desuden bruger vi tjenester fra OpenAI, Anthropic og Groq til at drive AI'en. Disse data behandles i USA.",
  "faq.question6": "Kan jeg få refusion?",
  "faq.answer6":
    "Den gratis prøve kan annulleres når som helst; men hvis du glemmer at annullere prøven, kan vi refundere dig inden for 14 dage, forudsat at du ikke har brugt tjenesten efter prøven udløb. Kontakt os venligst på support@talkio.ai",
  "faq.question7": "Hvilke betalingsmetoder accepterer I?",
  "faq.answer7":
    "Til abonnementer accepterer vi alle større kreditkort og Paypal. Til engangsindkøb accepterer vi også WeChat, Przelewy24, Giropay, Alipay og flere. Tilgængelighed afhænger af din placering.",
  "faq.question8": "Har Talkio en mobilapp?",
  "faq.answer8":
    "Talkio er en Progressive Web App, hvilket betyder, at du kan installere den fra din browser uden at gå til App Store eller Google Play. På Android, mens du bruger Talkio i din browser, finder du en installationsknap efter tilmelding. På iOS skal du åbne Talkio i Safari, trykke på delingsikonet og vælge 'Tilføj til startskærm' for at installere det som en almindelig app på din telefon.",
  "faq.question9": "Hvilke browsere kan jeg bruge?",
  "faq.answer9":
    "Talkio AI virker i alle moderne browsere, inklusive Chrome, Firefox, Safari og Edge. Det fungerer også på mobile browsere på iPhone og Android.",
  "faq.question10": "Hvilke AI-modeller bruger I?",
  "faq.answer10":
    "Talkio AI bruger en blanding af forskellige AI-modeller fra OpenAI, Anthropic og Meta. Hver model har en specifik opgave, som de er bedre til, og sammen skaber de en kraftfuld AI-lærer.",
  "faq.question11": "Hvordan adskiller Talkio AI sig fra Voice Control for ChatGPT?",
  "faq.answer11":
    "Talkio AI tilbyder en sammenhængende oplevelse med ChatGPT tilpasset til sprogindlæring. Det tilbyder også flere funktioner, herunder udtalelsesvurdering, premium stemmeteknologier, flersproglig støtte, kuraterede træningsmaterialer, integreret feedback og oversættelser.",
  "faq.question12": "Hvordan kan jeg kontakte dig?",
  "faq.answer12": "Du er meget velkommen til at skrive en email til hello@talkio.ai",
  "layout.serviceBar.text": "Grundlagt i Danmark. Vi respekterer dit privatliv.",
  "layout.serviceBar.community": "Deltag i et verdensomspændende fællesskab af sprogunderviserne",
  "layout.nav.pricing": "Priser",
  "layout.nav.faq": "FAQ",
  "layout.nav.languages": "Sprog",
  "layout.nav.blog": "Blog",
  "layout.nav.schools": "For Skoler",
  "layout.nav.teams": "For Teams",
  "layout.nav.affiliateProgram": "Affiliate-program",
  "layout.nav.signIn": "Log Ind",
  "layout.nav.goToApp": "Gå til appen",
  "layout.nav.menu": "Menu",
  "layout.cta.description":
    "Den ultimative sprogtræningsapp, der bruger AI-teknologi til at hjælpe dig med at forbedre dine mundtlige færdigheder.",
  "layout.cta.button": "Prøv Talkio AI",
  "layout.footer.pages": "Sider",
  "layout.footer.learningHub": "Læringshub",
  "layout.footer.blog": "Blog",
  "layout.footer.about": "Om",
  "layout.footer.affiliateProgram": "Affiliate-program",
  "layout.footer.schools": "Talkio for Skoler",
  "layout.footer.teams": "Talkio for Teams",
  "layout.footer.languageGuide": "Gratis Sprogguide for Begyndere",
  "layout.footer.termsConditions": "Vilkår & Betingelser",
  "layout.footer.privacyPolicy": "Fortrolighedspolitik",
  "layout.footer.logout": "Log ud",
  "layout.footer.login": "Log ind",
  "layout.footer.blogPosts": "Blogindlæg",
  "layout.footer.blogPost1": "Ny funktion på Talkio AI: Interaktiv Sprogguide",
  "layout.footer.blogPost2": "Talkio Integrerer Tre Nye Tilpassede GPT'er",
  "layout.footer.blogPost3":
    "Hæv Dine IELTS Tale færdigheder med Talkio AI: En Trin-for-Trin Guide",
  "layout.footer.blogPost4": "3 Effektive Måder at Øve Mundtlig Engelsk",
  "layout.footer.blogPost5": "Lægge dit Engelsk Færdighedstest",
  "layout.footer.blogPost6": "Mestring af TOEFL Tale med Talkio AI: Din Vigtige Guide",
  "layout.footer.contact": "Kontakt",
  "layout.footer.rights": "Alle rettigheder forbeholdes.",
  "layout.footer.illustrationCredits": "Illustrationer på forsiden af vectorjuice",
  "pricing.description.perMonthBilledOnce": "pr. måned - opkrævet én gang",
  "pricing.description.perMonthBilledQuarterly": "pr. måned - opkrævet kvartalsvis",
  "pricing.description.perMonthBilledBiannually": "pr. måned - opkrævet hver 6. måned",
  "pricing.description.perMonthBilledYearly": "pr. måned - opkrævet årligt",
  "pricing.description.perMonth": "pr. måned",
  "pricing.button.buyNow": "Køb nu med 7-dages penge-tilbage-garanti",
  "pricing.button.startFreeTrial": "Start din gratis prøve",
  "pricing.features.refund": "7-dages fuld refundering - annullér når som helst",
  "pricing.features.freeTrial": "7-dages gratis prøve - annullér når som helst",
  "pricing.features.supportsLanguages": "Understøtter 40+ sprog",
  "pricing.features.voiceConversations": "Livagtige stemmesamtaler",
  "pricing.features.instantFeedback": "Øjeblikkelig feedback",
  "pricing.features.weeklyProgressReport": "Ugentlig fremskridtsrapport",
  "pricing.features.pronunciationPractice": "Udtaleøvelse",
  "pricing.features.interactiveWordbook": "Interaktiv ordbog",
  "pricing.features.speechRecognition": "Flersproget talegenkendelse",
  "pricing.features.extraFeatures": "Rolle-spil, emner, quizzer og mere!",
  "accessRequest.pageNotExist": "404 Side findes ikke.",
  "accessRequest.createAccountTitle": "Opret en konto for at komme i gang",
  "accessRequest.createAccountMessage":
    "For at deltage skal du først oprette en konto. Efter tilmelding kan du anmode om adgang til systemet.",
  "accessRequest.createAccountButton": "Opret din konto",
  "accessRequest.alreadyHaveAccount": "Har du allerede en konto?",
  "accessRequest.signIn": "Log ind",
  "accessRequest.grantedTitle": "Tillykke!",
  "accessRequest.grantedMessage":
    "Du har fået adgang til {serviceName}. Klik nedenfor for at begynde at bruge appen.",
  "accessRequest.goToApp": "Gå til App",
  "accessRequest.accountExists": "Konto findes allerede",
  "accessRequest.accountExistsMessage":
    "Du er allerede medlem med denne konto. For at fortsætte, log venligst ud og tilmeld dig med en anden email.",
  "accessRequest.logOut": "Log Ud",
  "accessRequest.requestAccessTitle": "Anmod om adgang for at fortsætte",
  "accessRequest.requestAccessMessage": "Din anmodning skal godkendes, før du kan få adgang.",
  "accessRequest.checkBackLater":
    "Tjek venligst igen senere for at se, om din adgang er blevet givet.",
  "accessRequest.submitRequest": "Indsend anmodning",
  "accessRequest.pending": "Adgangsanmodning afventer",
};

export default l;
